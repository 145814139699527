import LogRocket from 'logrocket';

export function getSlug() {
    return !isKrediDomain() && !isDev() ? getHost() : getSubdomain();
}

export function getSubdomain() {
    const host = window.location.host;
    return host.split('.')[0];
}

export function isKrediDomain() {
    const host = window.location.host;
    return host.includes('kredi');
}

export function getHost() {
    return window.location.host;
}

export function isDev() {
    return import.meta.env.DEV;
}

export function isProduction() {
    return import.meta.env.REACT_APP_ENV === 'production'
}

export function getLogRocketUrl() {
    return new Promise((resolve, reject) => {
        LogRocket.getSessionURL((url) => resolve(url));
    });
}

export function getLogRocketSessionUrl(): string {
    if (['production'].includes(import.meta.env.REACT_APP_ENV || "")) {
        const url = LogRocket.sessionURL;
        return url?.includes('Must') ? '' : (url || '');
    } else {
        return "";
    }
}

export const getImageSource = (): string => {
    if (isDev() && isKrediDomain()) {
        return `https://kredi-dev.s3-us-west-2.amazonaws.com/uploads/app/logo.png`;
    }
    return `https://kredi-dev.s3-us-west-2.amazonaws.com/uploads/${getSubdomain()}/logo.png`;
};

export function getAuthResourceName(name: string) {
    const slug = getSlug()
    return `${slug}-${name}`;
}