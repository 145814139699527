import { Navigate, useLocation } from "react-router-dom";
import { authActions } from "src/store/auth/actions";
import { isProduction, segment } from "src/helpers";
import { store } from "src/store";

import { SidebarItem } from "src/components";

import {
	DashboardOutlined as HomeIcon,
	HomeOutlined as Home2Icon,
	AssignmentIndOutlined as ProfileIcon,
	MonetizationOnOutlined as MoneyIcon,
	FolderSharedOutlined as ApplicationIcon,
	PersonOutline as PersonIcon,
	ExitToApp as ExitToAppIcon
} from "@mui/icons-material";

import { SupportAgentIcon } from 'src/assets/icons';

import { HomeMain } from "./Main";
import { HomeMainLegacy } from "./_Main";
import { Applications } from "./Applications";
import { ProductApplications } from "./ProductApplications";
import { ProductApplication } from "./ProductApplications/ProductApplication";
import { Consultant } from "./Consultant";
import { ConsultantSchedule } from "./Consultant/Schedule";

import { ProfileMain } from "./Profile/Main";
import { PersonalData } from "./Profile/PersonalData";
import { ContactData } from "./Profile/ContactData";
import { Addresses } from "./Profile/Addresses";
import { AddressForm } from "./Profile/Addresses/Form";
import { Employments } from "./Profile/Employments";
import { EmploymentForm } from "./Profile/Employments/Form";

import { ApplicationSummaryPage } from "./Applications/Summary";
import { Documents } from "./Applications/Summary/_Documents";
import { CreditsPage } from "./Credit";
import { PaymentPage } from "./Credit/Payment";
import { CreditDetailsPage } from "./Credit/Details";
import { CapitalPrepaidPage } from "./Credit/CapitalPrepaid";
import { AmortTableHistoryPage } from "./Credit/Details/AmortTableHistory";
import { ParticipantsPage } from "./Credit/Participants";
import { AmortizationDetailsPage } from "./Credit/Details/AmortTableHistory/AmortizationDetails";
import { ContractsPage } from "./Credit/Contracts";
import { BankStatementsPage } from "./Credit/BankStatement";
import { ProceduresPage } from "./Credit/Procedures";
import { ProcedureDetailsPage } from "./Credit/Procedures/Details";
import { InteresConstancyPage } from "./Credit/InterestConstancy";
import { IncrementCardPage } from "./Credit/IncrementCard";
import { ProcedureNewPage } from "./Credit/Procedures/New";

import { ApplicationDocumentsPage } from "./Applications/Summary/Documents";
import { DocumentsPage } from "./Documents";

import { CreditProposalsPage } from "../CreditProposals";
import { CreditProposalDetailsPage } from "../CreditProposals/Details";

import { Application, CreditType, Customer, Route } from "src/types";
import { ApplicationPaymentsPage } from "../Application/Payments";

interface GetSidebarItemParams {
	lastOneApplication?: Application,
	credit?: CreditType
	customer?: Customer,
	isMobile?: boolean
	isKredi?: boolean
}

export const getSidebarItems = ({
	lastOneApplication,
	credit,
	customer,
	isMobile,
	isKredi
}: GetSidebarItemParams): SidebarItem[] => [
		// {
		// 	to: '/',
		// 	isMenuItem: false,
		// 	component: function Component() {
		// 		const location = useLocation()
		// 		return <Navigate to={`/home/main${location.search}`} />
		// 	}
		// },
		{
			name: 'Inicio',
			icon: HomeIcon,
			to: 'main',
			isMenuItem: true,
			component: HomeMain
		},
		{
			name: 'Inicio Antiguo',
			icon: HomeIcon,
			to: 'main_legacy',
			isMenuItem: false,
			component: HomeMainLegacy
		},
		{
			name: 'Inicio Nuevo',
			icon: HomeIcon,
			to: 'main_new',
			isMenuItem: false,
			component: HomeMain
		},
		{
			name: 'Documentos',
			to: 'main/:applicationId/documents',
			isMenuItem: false,
			component: DocumentsPage
		},
		{
			name: 'Propuestas',
			to: 'main/:applicationId/proposals',
			isMenuItem: false,
			component: CreditProposalsPage,
		},
		{
			name: 'Detalle de propuesta',
			to: 'main/:applicationId/proposals/:proposalId',
			isMenuItem: false,
			component: CreditProposalDetailsPage,
		},

		//Credit
		{
			name: 'Crédito',
			icon: Home2Icon,
			to: 'credit',
			isMenuItem: true,
			component: CreditsPage,
			hidden: !Boolean(lastOneApplication?.credit_id) || credit?.status != 'active',
		},
		{
			name: 'Detalles de Crédito',
			to: 'credit/:creditId',
			isMenuItem: false,
			component: CreditDetailsPage
		},
		{
			name: 'Detalle de amortización',
			to: 'credit/:creditId/amortization_table',
			isMenuItem: false,
			component: AmortizationDetailsPage
		},
		{
			name: 'Historial de amortización',
			to: 'credit/:creditId/history',
			isMenuItem: false,
			component: AmortTableHistoryPage
		},
		{
			name: 'Detalle de amortización',
			to: 'credit/:creditId/history/:id',
			isMenuItem: false,
			component: AmortizationDetailsPage
		},
		{
			name: 'Pago',
			to: 'credit/:creditId/payment',
			isMenuItem: false,
			component: PaymentPage
		},
		// {
		// 	name: 'Prepago de capital',
		// 	to: 'credit/:creditId/capital_prepaid',
		// 	isMenuItem: false,
		// 	component: CapitalPrepaidPage
		// },
		{
			name: 'Participantes del crédito',
			to: 'credit/:creditId/participants',
			isMenuItem: false,
			component: ParticipantsPage
		},
		{
			name: 'Contratos',
			to: 'credit/:creditId/contracts',
			isMenuItem: false,
			component: ContractsPage
		},
		{
			name: 'Estados de cuenta',
			to: 'credit/:creditId/bank_statements',
			isMenuItem: false,
			component: BankStatementsPage
		},
		{
			name: 'Trámites y aclaraciones',
			to: 'credit/:creditId/procedures',
			isMenuItem: false,
			component: ProceduresPage
		},
		{
			name: 'Nuevo Trámite',
			to: 'credit/:creditId/procedures/new',
			isMenuItem: false,
			component: ProcedureNewPage
		},
		{
			name: 'Detalles Trámite',
			to: 'credit/:creditId/procedures/:id',
			isMenuItem: false,
			component: ProcedureDetailsPage
		},
		{
			name: 'Constancia de interés',
			to: 'credit/:creditId/interest_constancy',
			isMenuItem: false,
			component: InteresConstancyPage
		},
		{
			name: 'Carta de incremento',
			to: 'credit/:creditId/increment_card',
			isMenuItem: false,
			component: IncrementCardPage
		},

		//Applications
		{
			name: 'Solicitudes',
			icon: ApplicationIcon,
			to: 'applications',
			isMenuItem: false,
			component: Applications
		},
		{
			name: 'Solicitudes',
			to: 'applications/summary/:applicationId/main',
			isMenuItem: false,
			component: ApplicationSummaryPage
		},
		{
			name: 'Documentos',
			to: 'applications/summary/:applicationId/documents',
			isMenuItem: false,
			component: ApplicationDocumentsPage
		},
		{
			name: 'Documentos',
			to: 'applications/:applicationId/payments/:productType',
			isMenuItem: false,
			hidden: isKredi,
			component: ApplicationPaymentsPage
		},


		{
			name: 'Cotizaciones',
			icon: MoneyIcon,
			to: 'product_applications',
			isMenuItem: true,
			component: ProductApplications,
			app: 'abacus',
			hidden: !isKredi
		},
		{
			name: 'Cotización',
			to: 'product_applications/details',
			isMenuItem: false,
			component: ProductApplication,
			app: 'abacus'
		},
		{
			name: 'Cotización',
			to: 'product_applications/details/:id',
			isMenuItem: false,
			component: ProductApplication,
			app: 'abacus'
		},
		{
			name: 'Asesoría',
			icon: SupportAgentIcon,
			to: 'consultant',
			isMenuItem: true,
			component: Consultant,
			hidden: (lastOneApplication?.status == "rejected" || !customer?.user)
		},
		{
			name: 'Asesor agenda',
			to: 'consultant/schedule',
			isMenuItem: false,
			component: ConsultantSchedule
		},
		{
			name: 'Perfil',
			icon: PersonIcon,
			to: 'profile',
			isMenuItem: true,
			component: ProfileMain
		},
		// {
		// 	name: 'Resultado',
		// 	to: 'result/:applicationId',
		// 	isMenuItem: false,
		// 	component: ResultPage
		// },
		{
			name: 'Cerrar sesión',
			icon: ExitToAppIcon,
			to: 'logout',
			hidden: !isMobile,
			isMenuItem: true,
			onClick: () => {
				store.dispatch(authActions.logout());
				segment.track('Customer', 'Logout');
			}
		},

		// profile
		{
			name: 'Datos personales',
			icon: ProfileIcon,
			to: 'profile/personal-data',
			isMenuItem: false,
			component: PersonalData
		},
		{
			name: 'Datos de contacto',
			to: 'profile/contact-data',
			isMenuItem: false,
			component: ContactData
		},
		{
			name: 'Direcciones',
			to: 'profile/addresses',
			isMenuItem: false,
			component: Addresses
		},
		{
			name: 'Direcciones',
			to: 'profile/addresses/:address_id',
			isMenuItem: false,
			component: AddressForm
		},
		{
			name: 'Direcciones',
			to: 'profile/addresses/new',
			isMenuItem: false,
			component: AddressForm
		},
		{
			name: 'Empleos',
			to: 'profile/employments',
			isMenuItem: false,
			component: Employments
		},
		{
			name: 'Empleos',
			to: 'profile/employments/:employment_id',
			isMenuItem: false,
			component: EmploymentForm
		},
		{
			name: 'Empleos',
			to: 'profile/employments/new',
			isMenuItem: false,
			component: EmploymentForm
		},
	]

export function convertSidebarItemsToRoutes(items: SidebarItem[]): Route[] {
	const routes: Route[] = items.map(item => {
		return {
			path: `/${item.to}`,
			component: item.component!,
			isPrivate: item.isMenuItem
		}
	})

	return routes
}