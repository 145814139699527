import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ChipProps, Grid, GridProps } from "@mui/material"
import { Loading } from "src/components/Loading"

// import { CreditDetails } from "./CreditDetails"
import { Stepper } from "./StagesStepper"
import { ApplicationStatus } from "./Status"

import { applicationActions, applicationRejectedDocuments } from "src/store/application/actions"

import { useStyles } from "./styles"
import _ from "lodash"
import { creditsActions } from "src/store/credits/actions"

export interface ApplicationStatusCardProps extends GridProps {
    chipProps?: ChipProps
}

export function ApplicationStatusCard({ chipProps, ...rest }: ApplicationStatusCardProps) {
    const { classes, cx } = useStyles()
    const dispatch = useDispatch()

    const { className: gridClassName, ...restGridProps } = rest || {}
    const isLoading = useSelector(state => state.applications.isLoading)
    const lastOneApplication = useSelector(state => state.applications.lastApplication)
    const credit = useSelector(state => state.credits.credits.credit)

    const currentStage = lastOneApplication?.applicationWorkflowStages?.find(stage => stage.stage_id === lastOneApplication?.current_stage?.id)

    const showStepper = lastOneApplication?.status
        && !['rejected', 'cancelled', 'approved'].includes(lastOneApplication.status)
        && !Boolean(lastOneApplication?.credit_id)

    useEffect(() => {
        dispatch(applicationActions.getLastOneApplication())
    }, [])

    useEffect(() => {
        if (lastOneApplication?.id) {
            dispatch(applicationRejectedDocuments.hasAny(lastOneApplication.id))
        }

        if (lastOneApplication?.credit_id) {
            dispatch(creditsActions.getOne(lastOneApplication.credit_id))
        }
    }, [lastOneApplication?.id])

    return (
        <Loading
            isLoading={isLoading}
            variant="skeleton"
            skeletonsNumber={1}
        >
            <Grid
                container
                item
                className={cx(classes.root, gridClassName)}
                {...restGridProps}
            >

                <Grid item xs={12}>
                    <ApplicationStatus
                        application={lastOneApplication}
                        currentStage={currentStage}
                        showCredit={credit?.status == 'active'}
                    />
                </Grid>

                {showStepper && (
                    <Grid item xs={12}>
                        <Stepper
                            currentApplicationWorkflowStage={currentStage}
                            application={lastOneApplication}
                        />
                    </Grid>
                )}

                {/* <Grid item xs={12}>
                    <Divider className={classes.divider} />
                </Grid>

                <Grid item xs={12}>
                    <CreditDetails />
                </Grid> */}

            </Grid>
        </Loading>
    )
}