import { Fragment } from "react"
import { Box, Button, Chip, Grid, Typography } from "@mui/material"
import HomeImg from 'src/assets/img/home_casa.svg'

import { Link } from "src/components"

import { Application, ApplicationWorkflowStage } from "src/types"
import { STAGE_STATUS, isCancelledOrRejected, useIsKredi } from "src/helpers"
import { getStatusInfo } from "./helpers"

import { useStyles } from "./styles"
import _ from "lodash"

interface ApplicationStatusProps {
    currentStage?: ApplicationWorkflowStage
    application?: Application
    showCredit?: boolean
}

export function ApplicationStatus({ application, currentStage, showCredit }: ApplicationStatusProps) {
    const { classes, cx } = useStyles()
    const isKredi = useIsKredi()

    const {
        title,
        subtitle,
        button: applicationButtonStatus,
        status: applicationStatus,
        Icon: ApplicationStatusIcon
    } = getStatusInfo(application, currentStage, showCredit) || {}

    const {
        title: statusTitle,
        backgroundColor: statusBgColor
    } = applicationStatus || {}

    const {
        title: buttonTitle,
    } = applicationButtonStatus || {}

    const continueApplicationLink = `/application/${application?.id}/${application?.current_stage?.slug}/${application?.current_section?.slug}`

    const canContinueApplication = !isCancelledOrRejected(application)
        && currentStage?.status !== STAGE_STATUS.IN_REVIEW
        && currentStage?.visibility == 'customer'

    const buttonLink = showCredit ? '/home/credit' : canContinueApplication
        ? continueApplicationLink
        : '/application'

    return (
        <Grid container className={classes.root}>
            <Grid item className={classes.infoContainer}>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography className={classes.title}>
                            {title}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className={classes.description}>
                            {subtitle}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Box
                            display='flex'
                            alignItems='center'
                            className={classes.actionsContainer}
                        >

                            {!_.isEmpty(applicationStatus) && (
                                <Chip
                                    className={classes.chip}
                                    color="default"
                                    label={statusTitle}
                                    style={{
                                        backgroundColor: statusBgColor
                                    }}
                                />
                            )}

                            {!_.isEmpty(applicationButtonStatus) && (
                                <Link to={buttonLink}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="medium"
                                    >
                                        {buttonTitle}
                                    </Button>
                                </Link>
                            )}

                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            {isKredi && (
                <Grid item className={classes.imageContainer}>
                    <ApplicationStatusIcon />
                </Grid>
            )}
        </Grid>
    )
}