import { ComponentType } from 'react';
import AppStatusDefaultImg from 'src/assets/img/application_status_solicita.svg';
import InReviewIconImg from 'src/assets/img/application_in_review.svg'
import PersonInReviewIconImg from 'src/assets/img/application_person_in_review.svg'
import ApprovedImg from 'src/assets/img/application_approved2.svg'
import RejectedImg from 'src/assets/img/application_rejected2.svg'
import PreapprovedImg from 'src/assets/img/application_preapproved.svg'
import CancelledImg from 'src/assets/img/application_cancelled2.svg'
import HomeImg from 'src/assets/img/home_casa.svg'
import HomeMobileImg from 'src/assets/img/home_casa_mobile.svg'
import HomeHasCreditImg from 'src/assets/img/home_has_credit_whands.svg'
import { Application } from 'src/types';

export interface ApplicationStatusInfo {
    title: string;
    description: string;
    Icon: any;
    IconMobile?: any;
    buttonTitle: string;
    disabled?: boolean;
    linkTo?: string;
}

const statusDefault = {
    title: "¡Solicita tu crédito hoy mismo!",
    description: "Inicia tu solicitud, solo te llevará unos minutos, fácil, rápido y en línea.",
    Icon: AppStatusDefaultImg,
    buttonTitle: 'Solicitar',
    linkTo: '/application'
}

export function getStatusInfo(
    application?: Application,
    isExpiredStatus?: boolean,
    hasRejectedDocuments?: boolean,
    showCredit?: boolean
): ApplicationStatusInfo {
    if (!application || isExpiredStatus) {
        return statusDefault;
    }
    if (showCredit) {
        return {
            title: "Conoce los detalles de tu crédito",
            description: "Dale seguimiento a tu crédito: pagos, asesoría, estados de cuenta.",
            Icon: HomeHasCreditImg,
            buttonTitle: 'Ver detalles',
            linkTo: `/home/credit`
        };
    }
    switch (application?.status || '') {
        // case 'pre_approved':
        //     return {
        //         title: "¡Tu solicitud ha sido preaprobada!",
        //         description: "Un ejecutivo se comunicará contigo para indicarte cuales son los siguientes pasos.",
        //         Icon: PreapprovedImg,
        //         buttonTitle: 'Ver detalles',
        //     }
        case 'rejected': {
            return {
                title: "Lo sentimos, tu solicitud fue rechazada",
                description: "¿Crees que hemos cometido un error? Comunícate con tu asesor, podemos ayudarte.",
                Icon: RejectedImg,
                buttonTitle: 'Ver detalles',
            };
        }

        case 'cancelled': {
            return {
                title: "Lo sentimos, tu solicitud fue cancelada",
                description: "¿Crees que hemos cometido un error? Comunícate con tu asesor, podemos ayudarte.",
                Icon: CancelledImg,
                buttonTitle: 'Contactar a mi asesor',
                linkTo: '/home/consultant'
            };
        }

        case 'approved':
            return {
                title: '¡Tu crédito ha sido aprobado!',
                description: "¡Felicidades! Haz recibido una propuesta de crédito.",
                Icon: ApprovedImg,
                buttonTitle: 'Ver propuesta',
                linkTo: '/',
                disabled: true
            }
        // case 'in_review':
        case 'in_process':
            if (hasRejectedDocuments) {
                return {
                    title: "Tienes documentos pendientes",
                    description: "Para poder continuar con tu proceso es necesario que actualices los documentos pendientes.",
                    Icon: PersonInReviewIconImg,
                    buttonTitle: 'Continuar solicitud',
                    linkTo: `/application/${application?.id}/${application?.current_stage?.slug}/${application?.current_section?.slug}?from=summary`
                }
            } else {
                return {
                    title: "Tu solicitud se encuentra en revisión",
                    description: "Estamos revisando tu expediente para darte una respuesta lo más pronto posible.",
                    Icon: PersonInReviewIconImg,
                    buttonTitle: 'Continuar solicitud',
                    linkTo: `/application/${application?.id}/${application?.current_stage?.slug}/${application?.current_section?.slug}?from=summary`
                }
            }

        case 'draft': {
            return {
                title: "¡Continua con tu solicitud y recibe tu crédito!",
                description: "Estás a solo unos clics de completar tu solicitud para comprar el hogar de tus sueños.",
                Icon: HomeImg,
                IconMobile: HomeMobileImg,
                buttonTitle: 'Continuar solicitud',
            }
        }

        default:
            return statusDefault;
    }
}