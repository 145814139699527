import { ChangeEvent, useEffect } from "react";
import { Grid, Typography, Card, Button } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";

import { TextField, Link } from "src/components";
import { useStyles } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "src/store/auth/actions";
import { segment } from "src/helpers";
import { RootState } from "src/store";
import { EmailInput } from "@kredimx/form-builder";

export function CreateAccount() {
    const { classes, cx } = useStyles();
    const dispatch = useDispatch();
    const createAccount = (email: string) => dispatch(authActions.createAccount(email));
    const isSegmentLoaded = useSelector((state: RootState) => state.auth.isSegmentLoaded);

    const { values, errors, touched, handleChange, handleBlur, handleSubmit } = useFormik({
        initialValues: { email: '' },
        enableReinitialize: true,
        onSubmit: values => {
            createAccount(values.email);
            segment.track('Customer', 'Created', { email: values.email })
        },
        validationSchema: yup.object().shape({
            email: yup.string().email('Debe ser un email válido').required('Ingresa un email')
        })
    });

    function onChange(event: ChangeEvent<HTMLInputElement>) {
        const { name, value } = event.target;
        const _value = value?.toLowerCase();
        handleChange({ ...event, target: { name, value: _value } });
    }

    useEffect(() => {
        if (isSegmentLoaded) {
            segment.page('CreateAccount');
        }
    }, [window.analytics])

    return (
        <Grid container className={classes.container}>
            <Grid container direction="column" alignItems="center" style={{ padding: 16 }}>
                <Card elevation={3} className={classes.card}>
                    <Typography variant="h3"> Crea tu cuenta </Typography>
                    <Typography> Te enviaremos un link mágico para crear tu cuenta <br /> sin necesidad de contraseña. </Typography>
                    <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                        <EmailInput
                            //@ts-ignore
                            label="Correo"
                            name="email"
                            autoComplete="email"
                            type="email"
                            value={values.email}
                            onChange={onChange}
                            onBlur={handleBlur}
                            error={errors.email}
                            touched={touched.email}
                            // emailValidationRequest={async (email: string) => (
                            //     sendgridClient.request({
                            //         url: `/v3/validations/email`,
                            //         method: 'POST',
                            //         body: { email, source: 'signup' }
                            //     })
                            // )}
                            config={{
                                disableSuggestions: true
                            }}
                        />
                    </form>
                    <div className={classes.buttonContainer}>
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            type="submit"
                            data-cy="submit-button"
                            className={classes.button}
                            onClick={() => handleSubmit()}
                            fullWidth
                        >
                            Crear mi cuenta
                        </Button>
                    </div>
                </Card>
                <Typography className={classes.text}>
                    ¿Ya tienes una cuenta?
                    <Link to="/magic_link"> Inicia sesión </Link>
                </Typography>
            </Grid>
        </Grid>
    )
}