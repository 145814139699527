import { useEffect } from "react";
import { Organization } from "src/types";

import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import { segment } from "../segment";
import { Bugsnag } from "../bugnsag";
import { init as abacusInit } from "@kredimx/abacus-components";

import { useDispatch, useSelector } from "react-redux";
import { authActions } from "src/store/auth/actions";
import { getFullName } from "..";

function initLogRocket(api_key: string) {
    if (['production'].includes(import.meta.env.REACT_APP_ENV || "")) {
        if (!LogRocket.sessionURL || (LogRocket.sessionURL || '').includes('Must')) {
            LogRocket.init(api_key);
            setupLogRocketReact(LogRocket);
            Bugsnag.addOnError((event) => {
                event.addMetadata('LogRocket', {
                    sessionURL: LogRocket.sessionURL,
                });
            });
        }
    }
}

/**
 * Initializes organization installed apps
 * @description
 * - LogRocket: To tracking user session in video
 * - Segment: To tracking user events
 * @param organization 
 */
export function useInstalledApps(organization: Organization) {
    const dispatch = useDispatch()
    const customer = useSelector(state => state.auth.customer);

    useEffect(() => {
        if (organization?.id) {

            const segmentKey = organization.installed_apps?.segment?.api_key;
            const logrocketKey = organization.installed_apps?.logrocket?.onboarding_api_key;

            if (logrocketKey) {
                initLogRocket(logrocketKey!);
            }

            if (segmentKey && import.meta.env.NODE_ENV != 'development' && import.meta.env.REACT_APP_ENV != 'staging') {
                segment.load(segmentKey);
                dispatch(authActions.isSegmentLoaded());
            }

            abacusInit({
                apiUrl: import.meta.env.REACT_APP_ABACUS_URL,
                env: import.meta.env.REACT_APP_ENV,
                organizationSlug: organization.slug
            })
        }
    }, [organization?.id])

    useEffect(() => {
        if (Boolean(customer.id)) {
            LogRocket.identify(customer.id!, {
                name: getFullName(customer),
                email: customer.email || "",
            });
            Bugsnag.setUser(
                customer.id,
                customer.email,
                getFullName(customer)
            );
        }
    }, [customer.id])
}